@import "./styles/abstracts/variables";

html {
  overflow-y: scroll!important;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: "BwAletaNo10", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $accent;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex: 1 1 0;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: $accent;
  padding: 0 9px 0 0;
  display: flex;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: $accent;
    text-decoration: none;
  }
}

.MuiDialog-root {
  .MuiBackdrop-root {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%);
    z-index: -1;
  }
}
