@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.currentAccountContainer {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: rgba(145, 158, 171, 0.12);
  border-radius: 12px;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 16px;

    .title {
      font-family: $globalFont;
      font-weight: 600;
      font-size: 14px;
    }

    .companyName {
      font-family: $globalFont;
      font-weight: 600;
      font-size: 14px;
      margin-top: 4px;
    }

    .subTitle {
      font-family: $globalFont;
      font-weight: 500;
      font-size: 12px;
      color: $primary-light;
      margin-top: 4px;
    }
  }
}