$globalFont: 'Signika', sans-serif;
$globalLineHeight: 1.167;
$globalFontSizeBig: 2.0625rem;

$background-color: #FFFFFF;
$foreground-color: rgba(77, 201, 242, 0.4);
$accent: rgb(68,159,252);
$accentSecondary: #FFFFFF;
$accentSecondaryLight: rgba(54, 94, 181, 0.8);
$accentLight: rgba(44, 52, 124, 0.7);
$accentLightest: rgba(44, 52, 124, 0.1);

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-button-background: rgb(68,159,252);
$primary-button-background-hover: rgb(47, 128, 209);
$primary-button-shadow: rgb(68,159,252) 0 2px 4px 0;

$label: rgba(34, 51, 84, 0.7);
$label-focused: rgb(68,159,252);
$label-error: rgb(255, 72, 66);

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: rgb(68,159,252);
$input-border-error: rgb(255, 72, 66);
$input-error-text: rgb(255, 72, 66);

$primary-color: #E13119;
$primary-color-darker: #E35724;
$primary-color-bright: #ff7d58;
$primary-color-brighter: #ff7d58;
$primary-color-brightest: #ffb59f;

$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #FFFFFF;
$black-color: #000000;

$black-border-color: rgba(0, 0, 0, 0.3);

$black-divider-color: rgba(0, 0, 0, 0.1);

$red-color: #E13119;
$red-color-darker: #e04321;
$red-color-brighter: #EFC5BD;

$warning-color: #F19A3E;
$warning-color-brighter: #F6C48D;
$warning-color-darker: #98540B;

$sidebar-color: #32BBE6;
$navigation-color: #32BBE6;

$balance-green-color: #36b571;

$color-grey-light: rgb(218, 222, 227);
$color-grey: rgb(126, 136, 143);
$primary-main: #223D3C;

$primary-light: #42a5f5;

$shadow-color: rgba(145, 158, 171, 0.24);
$form-input-color: rgb(33, 43, 54);

$greenish-blue-color: rgba(34, 61, 60, 0.10);

$overview-card-background: #f2f3f5;
$overview-card-border: #4B4B4B;

$overview-card-completed-color: rgb(34, 154, 22);
$overview-card-completed-background: #e3f9dd;
$overview-card-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

$overview-card-cancelled-color: rgb(132, 0, 18);
$overview-card-cancelled-background: #ffe1e0;

$overview-header-background: #ecf5ff;
$overview-header-color: #449ffc;

$overview-header-without-team-background: rgb(251, 184, 0);
$overview-header-without-team-color: rgba(255, 213, 0, 0.214);

$overview-period-background: #efefef;
$overview-period-border: #ffffff;
$overview-last-period-background: #449ffc;

$overview-slot-border: #dbdbdb89;
$overview-hour-slot-border: rgb(203, 203, 203);

$overview-scrollbar-thumb: #888;
$overview-scrollbar-track: #f1f1f1;
$overview-scrollbar-thumb-hover: #555;