@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.companySelectForm {
  width: 100%;

  .mainInputs {
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 2px 0, rgba(145, 158, 171, 0.24) 0 16px 32px -4px;
    color: rgb(33, 43, 54);
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
