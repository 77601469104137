@import "../../styles/abstracts/variables";

.autocomplete {
  :global(.MuiAutocomplete-inputRoot) {
      padding-right: 9px !important;
    }
}

.textField {
  width: 100%;

  &.currencyInput {
    p {
      margin: 1px 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 8px 14px 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 50px;
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 14px;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 8px;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 400;
    line-height: 1.4375;

    input {
      height: 100%;
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 8px 14px 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }

  textarea {
    height: 100% !important;
  }
}

.fileField {
  border: 1px dashed rgba(145, 158, 171, 0.32);
  background-color: rgb(244, 246, 248);
  transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  padding: 20px 20px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
  text-align: left;
  overflow: hidden;
  display: flex;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  input {
    display: none;
  }

  .imageContainer {
    width: 160px;
    height: 130px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .label {
    margin-left: 16px;
    padding: 24px;

    h5 {
      margin: 0 0 8px;
      font-weight: 700;
      line-height: 1.5;
      font-size: 1.125rem;
      font-family: $globalFont;
    }

    p {
      margin: 0;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);
    }
  }
}

.switcherContainer,
.radioContainer {
  margin-top: 10px;

  :global(.Mui-checked) {
    color: rgb(68,159,252) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: rgb(68,159,252) !important;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $globalFont;
    font-weight: 400;
  }
}

.radioContainer {

  > label {
    padding-bottom: 8px;
  }
}